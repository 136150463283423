<template>
  <header class="navbar header-navbar fixed-top">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <ToggleMenu />
      <Logo />
      <UserDropDown />
    </div>
  </header>
</template>

<script>
import Logo from "../component/AppHeader/Logo.vue";
import ToggleMenu from "../component/AppHeader/ToggleMenu.vue";
import UserDropDown from "../component/AppHeader/UserDropDown.vue";

import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  components: {
    Logo,
    ToggleMenu,
    UserDropDown,
  },
  setup() {
    const expo = {};
    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
