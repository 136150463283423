export default [
  {
    title: 'Tanımlamalar',
    icon: 'fad fa-clipboard-list',
    children: [
      {
        title: 'Kategoriler',
        route: 'kategoriler',
        icon: 'fad fa-list-alt',
      },
      {
        title: 'Bölgeler',
        route: 'bolgeler',
        icon: 'fad fa-location',
      },
      {
        title: 'Teslimat Saatler',
        route: 'teslimat-saatler',
        icon: 'fad fa-clock',
      },
      {
        title: 'Markalar',
        route: 'markalar',
        icon: 'fad fa-id-card-alt',
      },
      {
        title: 'Stok Birimleri',
        route: 'stok-birimleri',
        icon: 'fad fa-person-carry',
      },
      {
        title: 'Varyant Grupları',
        route: 'varyant-gruplari',
        icon: 'fad fa-poll-people',
      },
      {
        title: 'Renk Tanımları',
        route: 'renk-tanimlari',
        icon: 'fad fa-brush',
      },
      {
        title: 'Siparis Durumları',
        route: 'siparis-durumlari',
        icon: 'fad fa-shopping-basket',
      },
      {
        title: 'Üye Grupları',
        route: 'uye-gruplari',
        icon: 'fad fa-users-medical',
      },
      {
        title: 'Kargo Tanımlamaları',
        route: 'kargo',
        icon: 'fad fa-truck',
      },
      {
        title: 'Kampanyalar',
        route: 'kampanyalar',
        icon: 'fad fa-bullhorn',
      },
      {
        title: 'Kuponlar',
        route: 'kuponlar',
        icon: 'fad fa-ticket',
      },
      {
        title: 'Harc Hesaplama',
        route: 'harc-hesaplama',
        icon: 'fad fa-ticket',
      },
      // {
      //   title: 'Filtreleme Ayar',
      //   route: 'filtreleme ',
      //   icon: 'fad fa-filter',
      // },
    ],
  },
];
