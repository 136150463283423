import store from '@/store';

export const userRoles = (item) => {
  const master = store.getters.getUserData.master;
  const roles = store.getters.getUserRole.authority;
  const turu = store.getters.getUserFirma.turu;


  if (master === 'admin') {
    return true;
  } else {
    for (const key in roles) {
      if (item.route === 'dashboard') return true;
      if (item.route === 'firmalar') return false;
      if (roles[key].modul == 'magaza-yonetim') {
        if (
          item.route === 'sayfalar' ||
          item.route === 'slider' ||
          item.route === 'banner' ||
          item.route === 'splash-bildirimler' ||
          item.route === 'medya-ortam' ||
          item.title === 'Mağaza İşlemleri'
        ) {
          return roles[key]['listeleme'];
        }
      }
      if (roles[key].modul == 'urun-islemleri') {
        if (item.route === 'urunler') {
          if (turu != 'tanitim')
            return roles[key]['listeleme'];
        }
      }
      if (roles[key].modul == 'siparis-islemleri') {
        if (item.title === 'Siparişler' || item.route === 'siparisler' || item.route === 'bildirimler')
          if (turu != 'tanitim')
            return roles[key]['listeleme'];
      }
      if (roles[key].modul == 'finans-islemleri') {
        // return false;
      }
      if (roles[key].modul == 'tanimlamalar') {
        if (turu == 'eticaret') {
          if (
            item.title === 'Tanımlamalar' ||
            item.route === 'kategoriler' ||
            item.route === 'bolgeler' ||
            item.route === 'teslimat-saatler' ||
            item.route === 'markalar' ||
            item.route === 'stok-birimleri' ||
            item.route === 'varyant-gruplari' ||
            item.route === 'renk-tanimlari' ||
            item.route === 'siparis-durumlari' ||
            item.route === 'urun-ek-ozellikler' ||
            item.route === 'uye-gruplari' ||
            item.route === 'kargo' ||
            item.route === 'kampanyalar' ||
            item.route === 'kuponlar' ||
            item.route === 'harc-hesaplama'
          )
            return roles[key]['listeleme'];
        } else {
          if (
            item.title === 'Tanımlamalar' ||
            item.route === 'kategoriler' ||
            item.route === 'harc-hesaplama'
          )
            return roles[key]['listeleme'];
        }

      }
      if (roles[key].modul == 'raporlar') {
        if (turu == 'eticaret') {
          if (
            item.title === 'Raporlar' ||
            item.route === 'yorumlar' ||
            item.route === 'uyeler' ||
            item.route === 'siparis-raporu' ||
            item.route === 'urun-satis-raporu' ||
            item.route === 'stok-risk-raporu' ||
            item.route === 'urun-haber-ver-raporu' ||
            item.route === 'abone-olan-rapor-listele'
          )
            return roles[key]['listeleme'];
        } else {
          if (
            item.title === 'Raporlar' ||
            item.route === 'yorumlar' ||
            item.route === 'uyeler' ||
            item.route === 'urun-haber-ver-raporu' ||
            item.route === 'abone-olan-rapor-listele'
          )
            return roles[key]['listeleme'];
        }
      }
      if (roles[key].modul == 'kullanicilar') {
        if (item.title === 'Kullanıcılar' || item.route === 'kullanici-listele' || item.route === 'kullanici-yetkiler') {
          return roles[key]['listeleme'];
        }
        if (item.route === 'profilim') {
          return roles[key]['duzenleme'];
        }
      }
      if (roles[key].modul == 'ayarlar') {
        if (item.route === 'ayarlar' || item.route === 'tema-ayar') return roles[key]['listeleme'];
      }
    }
    return false;
  }
};
