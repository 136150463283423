export default [
  {
    title: 'Raporlar',
    icon: 'fad fa-file-chart-line',
    children: [
      {
        title: 'Yorumlar',
        route: 'yorumlar',
        icon: 'fad fa-comment-lines',
      },
      {
        title: 'Üyeler',
        route: 'uyeler',
        icon: 'fad fa-users',
      },
      {
        title: 'Sipariş Raporları',
        route: 'siparis-raporu',
        icon: 'fad fa-cart-arrow-down',
      },
      {
        title: 'Ürün Satış Raporu',
        route: 'urun-satis-raporu',
        icon: 'fad fa-pallet-alt',
      },
      {
        title: 'Stok Risk Raporu',
        route: 'stok-risk-raporu',
        icon: 'fad fa-pallet-alt',
      },
      {
        title: 'Haber ver Ürün Raporu',
        route: 'urun-haber-ver-raporu',
        icon: 'fad fa-comment-smile',
      },
      {
        title: 'Abone Olanlar',
        route: 'abone-olan-rapor-listele',
        icon: 'fad fa-comment-smile',
      },
    ],
  },
];
