import dashboard from './dashboard';
import magaza from './magaza';
import product from './urunler';
import siparisler from './siparisler';
import tanimlamalar from './tanimlamalar';
import raporlar from './raporlar';
import kullanicilar from './kullanicilar';
import yonetim from './yonetim';

export default [...dashboard, ...magaza, ...product, ...siparisler, ...tanimlamalar, ...raporlar, ...kullanicilar, ...yonetim];
