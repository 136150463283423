export default [
  {
    title: 'Siparişler',
    icon: 'fad fa-receipt',
    children: [
      {
        title: 'Siparişler',
        route: 'siparisler',
        icon: 'fad fa-receipt',
      },
      {
        title: 'Bildirim Formları',
        route: 'bildirimler',
        icon: 'fad fa-mailbox',
      },
    ],
  },
];
