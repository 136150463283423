<template>
  <div class="app-content content">
    <div class="content-body">
      <transition :name="transitionEffect" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
export default defineComponent({
  setup() {
    const expo = {};
    expo.transitionEffect = computed(() => store.getters.getTransitionEffect);
    expo.toggleSideMenu = computed(() => store.getters.getToggleSideMenu);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.app-content {
  // padding: 6rem 2rem 2rem 2rem;
}
.content {
  display: block;
  width: calc(100% - 260px);
  min-height: 100%;
  height: 100%;
  margin-left: 260px;
  position: relative;
  transition: all 0.3s ease;
  backface-visibility: hidden;
  min-height: calc(100% - 3.35rem);
  background: #fff;
  padding-top: 4rem;
  @media only screen and (max-width: 992px) {
    margin-left: 0px;
    width: 100%;
  }
  .content-body {
    width: 100%;
    height: 100%;
  }
}
</style>
