<template>
  <div>
    <div class="top-bar-overlay" :class="{ active: toggleMenu }" />
    <ul class="top-bar-menu">
      <li class="top-bar-menu-item">
        <a href="#">
          <i class="fad fa-user fa-2x"></i>
          <i class="fa fa-caret-down"></i>
        </a>
        <ul class="top-bar-sub-menu" :class="{ open: toggleMenu }">
          <li
            class="top-bar-sub-menu-item"
            v-for="(item, index) in dropDownMenu"
            :key="index"
            v-if="userRole(item) || item.visible"
          >
            <DropDownItem :item="item" />
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, defineComponent, computed, onMounted } from '@vue/composition-api';

import { userRoles } from '@/libs/user_role';
import DropDownItem from './DropDownItem.vue';
export default defineComponent({
  components: { DropDownItem },
  setup() {
    const expo = {};
    expo.toggleMenu = ref(false);

    expo.dropDownMenu = ref([
      {
        title: 'Ayarlar',
        icon: 'cog',
        route: 'ayarlar',
      },
      {
        title: 'Tema Ayarlari',
        icon: 'theater-masks',
        route: 'tema-ayar',
      },
      {
        title: 'Profilim',
        icon: 'user',
        route: 'profilim',
      },
      {
        title: 'Çıkış Yap',
        icon: 'lock',
        route: '',
        visible: true,
      },
    ]);

    // expo.handlerToggleMenu = () => {
    //   expo.toggleMenu.value = !expo.toggleMenu.value;
    // };

    onMounted(() => {
      const topMenu = document.querySelector('.top-bar-menu');
      const topOverlay = document.querySelector('.top-bar-overlay');
      function toggleMenu() {
        expo.toggleMenu.value = !expo.toggleMenu.value;
      }
      topMenu.addEventListener('click', toggleMenu);
      topOverlay.addEventListener('click', toggleMenu);
    });

    expo.userRole = computed(() => {
      return (val) => userRoles(val);
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.top-bar-overlay {
  position: absolute !important;
  // z-index: 2;
  // background-color: rgba(0, 0, 0, 0.5);
  left: 0px;
  top: 0px;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  min-width: 100%;
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}
.top-bar-menu {
  padding-right: 20px;
  margin: 2px 0px;
  .top-bar-menu-item {
    position: relative;
    display: inline-block;
    margin: 0;
    > a {
      display: block;
      padding: 8px 0px;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 400;
      transition: all 0.2s ease-in-out;
      color: inherit;
      i {
        padding-left: 2px;
        transition: all 0.2s ease-in-out;
      }
      span {
        text-transform: uppercase;
      }
      @media only screen and (max-width: 992px) {
        span {
          display: none;
        }
      }
    }

    .top-bar-sub-menu {
      position: absolute;
      min-width: 200px;
      left: auto;
      right: 0;
      z-index: 10;
      background: #fff;
      border-radius: 0 0 4px 4px;
      border: 1px solid #eee;
      border-top: 3px solid #777;
      transition: all 0.2s ease-in-out;
      transform: translateY(10px);
      list-style: none;
      text-align: left;
      opacity: 0;
      visibility: hidden;
      color: rgb(72, 72, 72);
      padding: 0 0;
      top: 56px;

      &.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
      .top-bar-sub-menu-item {
        display: block;
        border-bottom: 1px solid #eee;
        padding: 10px 10px;
        &:last-child {
          border-bottom: none;
        }
        a {
          margin-left: 5px;
          display: block;
          transition: all 0.2s ease-in-out;
          text-transform: uppercase;
          color: inherit;
          i {
            font-size: 20px;
            padding-right: 10px;
          }
          &:hover {
            transform: translateX(10px);
          }
        }
      }
    }
  }
}
</style>
